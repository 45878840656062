import React, { useRef, useState } from "react";
import IframeResizer from "iframe-resizer-react";

import PropTypes from "prop-types";
import MessageData from "../messageData";

const Embed = ({ url, scrolling, style }) => {
  const ref = useRef(null);
  const [messageData, setMessageData] = useState(undefined);

  const onResized = (data) => setMessageData(data);

  const onMessage = (data) => {
    setMessageData(data);
    ref.current.sendMessage("Hello back from parent page");
  };

  return (
    <>
      <div id="iframeResizer-top"></div>
      <IframeResizer
        log
        inPageLinks
        forwardRef={ref}
        onMessage={onMessage}
        onResized={onResized}
        src={url}
        width="100%"
        scrolling={scrolling}
        style={style}
      />
      <messageData />
      <div id="iframeResizer-bottom"></div>
    </>
  );
};

MessageData.defaultProps = {
  data: undefined,
};

MessageData.propTypes = {
  data: PropTypes.object,
};

export default Embed;
