import { useEffect } from "react";

const usePreload = (url) => {
  useEffect(() => {
    const script = document.createElement("link");

    script.href = url;
    script.as = "document";
    script.rel = "preload";

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [url]);
};

export default usePreload;
