import React from "react";
import LayoutExamples from "../../../layouts/LayoutExamples";
import Seo from "../../../components/seo";
import { Row, Col, Divider, Alert } from "antd";
import ButtonsNavigation from "../../../components/ui/ButtonsNavigation.js";
import usePreload from "../../../components/hooks/usePreload.js";
import Embed from "../../../components/ui/Embed.js";

const ExampleEmbedPage1 = () => (
  <LayoutExamples>
    <Seo
      title="Embedded example 1"
      description="How inFlow charts behave in an embedded environment. inFlow helps professional organisations create interactive decision-making tools such as flow charts, decision trees or algorithms. Improve your process and increase engagement with our world-class tools."
    />
    {usePreload(
      "https://www.inflowchart.com/chart/examples/depressiviteit/deck?embedded=true"
    )}
    <Row
      justify="center"
      align="middle"
      style={{ padding: "48px 0 48px 0", backgroundColor: "#f1f3f5" }}
    >
      <Col xs={22} sm={20} md={20} lg={16} xl={12}>
        <Alert
          message="All content within the yellow border is loaded through a resizing embed. Note the fullscreen button which is visible in this embedded environment."
          type="info"
          showIcon
        />
      </Col>
    </Row>
    <Row
      style={{ padding: "0px 0 48px 0", backgroundColor: "#f1f3f5" }}
      justify="center"
      align="middle"
    >
      <Col xs={24} sm={24} md={20} lg={16} xl={12}>
        <Embed
          url="https://www.inflowchart.com/chart/examples/depressiviteit/deck?embedded=true"
          scrolling="no"
          style={{ border: "2px dashed rgb(245, 183, 0)" }}
        />
      </Col>
    </Row>
    <Divider dashed style={{ margin: "0" }} />
    <Row
      justify="center"
      align="middle"
      style={{ backgroundColor: "#f8f9fa", padding: "48px 0" }}
    >
      <Col>
        <ButtonsNavigation
          linkPrevious="Previous"
          linkPreviousTo="/examples/medical/example-3"
          linkNext="Next embedded example"
          linkNextTo="/examples/embed/example-2"
        />
      </Col>
    </Row>
  </LayoutExamples>
);

export default ExampleEmbedPage1;
